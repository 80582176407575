import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import PageIntro from '../components/page-intro';
import PostList from '../components/post-list';
import styled from 'styled-components';

const DienstTemplate = ({ data }) => {
  const { frontmatter, excerpt, html } = data.markdownRemark;
  const projects = data.allMarkdownRemark.nodes;
  const totalCount = data.allMarkdownRemark.totalCount;

  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description || excerpt}
    >
      <PageHeader header>
        <DienstTitle>{frontmatter.title}</DienstTitle>
      </PageHeader>
      <PageIntro>
        <DienstDescription dangerouslySetInnerHTML={{ __html: html }} />
      </PageIntro>

      <PostList posts={projects} />
    </Layout>
  );
};

export default DienstTemplate;

const DienstTitle = styled.h1`
  font-size: var(--size-700);
`;

const DienstDescription = styled.div`
  text-align: left;

  & > * + * {
    margin-top: var(--size-300);
  }

  & > p + p {
    margin-top: var(--size-700);
  }
`;

export const pageQuery = graphql`
  query DienstBySlug($slug: String!, $tags: [String!]) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
      }
    }

    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tags } }
        fields: { contentType: { eq: "projecten" } }
      }
    ) {
      totalCount
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          tags
          title
          image
        }
        timeToRead
        excerpt
      }
    }
  }
`;
